<template>
  <div>
    <v-dialog
      v-model="viewDialog"
      persistent
      max-width="700px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Payment Invoice') }}: {{ data.invoice_no }}
        </v-card-title>

        <v-card-text v-if="viewDialog">
          <v-simple-table
            id="print-area"
            class="print-table"
            dense
          >
            <template #default>
              <thead>
                <invoice-header />

                <tr>
                  <th
                    colspan="100"
                    class="d-none d-print-table-cell text-center text-subtitle-1 pb-4"
                  >
                    {{ $t('Payment Invoice') }}
                  </th>
                </tr>

                <tr>
                  <th class="text-start h-25px">
                    {{ $t('Supplier') }}: <span class="font-weight-regular">{{ data.supplier.name }}</span>
                  </th>
                  <th class="text-center h-25px">
                    {{ $t('Invoice No.') }}: <span class="font-weight-regular">{{ data.invoice_no }}</span>
                  </th>
                  <th class="text-end h-25px">
                    {{ $t('Date') }}: <span class="font-weight-regular">{{ $_format_date(data.action_date) }}</span>
                  </th>
                </tr>

                <tr>
                  <th class="text-start h-25px">
                    {{ $t('Amount') }}: <span class="font-weight-regular">$ {{ $_format_number(data.amount) }}</span>
                  </th>
                  <th class="text-center h-25px">
                    {{ $t('Old Loan') }}: <span class="font-weight-regular">$ {{ $_format_number(data.old_loan) }}</span>
                  </th>
                  <th class="text-end h-25px">
                    {{ $t('Current Loan') }}: <span class="font-weight-regular">$ {{ $_format_number(data.current_loan) }}</span>
                  </th>
                </tr>

                <tr>
                  <th
                    colspan="100"
                    class="text-start h-25px"
                  >
                    {{ $t('Note') }}: <span class="font-weight-regular">{{ data.note || '' }}</span>
                  </th>
                </tr>
              </thead>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <!-- <v-btn
            color="primary"
            depressed
            small
            @click="$_print()"
          >
            <v-icon left>
              mdil-printer
            </v-icon>
            <span class="pe-2">
              {{ $t('Print') }}
            </span>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    viewDialog: { type: Boolean, default: false },
    data: { type: Object, default: () => { } },
  },
}
</script>
<style>
.h-25px {
  height: 25px !important;
}
.w-100 {
  width: 100% !important;
}
.border-none {
  border: none !important;
}
</style>