<template>
  <div>
    <app-datatable
      add-permission="add_payment"
      show-branch-filter
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded()"
      @add-new="addNew()"
    >
      <template v-slot:amount="slotProp">
        <span class="d-inline-block dir-ltr">
          $ {{ $_format_number(slotProp.item.amount) }}
        </span>
      </template>

      <template v-slot:action_date="slotProp">
        {{ $_format_date(slotProp.item.action_date) }}
      </template>

      <!-- <template v-slot:created_at="slotProp">
        <span class="d-inline-block dir-ltr">
          {{ $_format_date_time(slotProp.item.created_at) }}
        </span>
      </template> -->

      <!-- Actions -->
      <template
        v-if="$_cans(['change_payment', 'delete_payment'])"
        v-slot:actions="slotProp"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdil-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="viewRow(slotProp.item.id)">
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  class="fs-18"
                >
                  mdil-printer
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('View') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$_can('change_payment')"
              @click="updateRow(slotProp.item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="info"
                  class="fs-18"
                >
                  mdil-pencil
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Edit') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$_can('delete_payment')"
              @click="deleteRow(slotProp.item.id)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="error"
                  class="fs-18"
                >
                  mdil-delete
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Delete') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </app-datatable>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="datatable.resetTableData = true"
      @update-table-data="datatable.updateTableData = true"
    />

    <view-payment
      :view-dialog="viewDialog"
      :data="formData"
      @close="viewDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'
import ViewPayment from './ViewPayment.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    AddEdit,
    ViewPayment,
  },

  data() {
    return {
      addEditDialog: false,
      viewDialog: false,
      formData: {},
      editMode: false,
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          { text: this.$t('Invoice No.'), value: 'invoice_no', sortable: true },
          { text: this.$t('Supplier'), value: 'supplier.name', sortable: false },
          { text: this.$t('Supplier Invoice No.'), value: 'supplier_invoice_no', sortable: false },
          { text: this.$t('Amount'), value: 'amount', sortable: true },
          { text: this.$t('Note'), value: 'note', sortable: true },
          { text: this.$t('User'), value: 'user', sortable: false },
          { text: this.$t('Action Date'), value: 'action_date', sortable: true },
          // { text: this.$t('Created At'), value: 'created_at', sortable: true },
          { text: this.$t('Actions'), value: 'actions', sortable: false, align: 'center' },
        ],
        tableSlots: [
          'amount',
          'action_date',
          'created_at',
          'actions',
        ]
      },
      endPoint: 'payment/'
    }
  },

  mounted() {
    this.$_section_title({ title: 'Payments', icon: 'mdil-note-text' })
    this.getSuppliersList()
  },

  methods: {
    ...mapActions(['getSuppliersList']),

    handleDataLoaded() {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
    },

    addNew() {
      this.formData = {
        action_date: this.$_format_date_for_picker(new Date())
      }
      this.editMode = false
      this.addEditDialog = true
    },

    viewRow(id) {
      axios.get(`payment/${id}/`).then(res => {
        this.formData = res.data
        this.viewDialog = true
      })
    },

    updateRow(data) {
      this.formData = { ...data }
      this.editMode = true
      this.addEditDialog = true
    },

    deleteRow(id) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(this.endPoint + id + '/').then(() => {
            this.$_notify('Deleted successfully')
            this.datatable.resetTableData = true
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>